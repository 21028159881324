<template>
  <div>
    <Loading :isActive="loading"/>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab :title="descPlural">
        <ListaParametros
          url="/admin/parametros"
          :descPlural="descPlural"
          :descSingular="descSingular"
        />
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import ListaParametros from './ListaParametros';

export default {
  name: 'parametros',
  components: {
    ListaParametros,
  },
  data() {
    return {
      loading: false,
      descPlural: 'Parâmetros',
      descSingular: 'Parâmetro',
    };
  },
  methods: {
    loadDescricaoModulo() {
      const {modulos} = JSON.parse(localStorage.getItem('app'));
      const {descPlural, descSingular} = modulos.find(
          (m) => m.nome === 'ADMIN_PARAMETRO'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
  },
  beforeMount() {
    this.loadDescricaoModulo();
  },
};
</script>
